@import "./bower/bootstrap/less/bootstrap";
@import "./bower/font-awesome/less/font-awesome";
@import "fonts";
@import "variables";

* {
   text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.overflow-hidden { overflow: hidden !important; }
.overflow-x-hidden { overflow-x: hidden !important; }
.overflow-y-hidden { overflow-y: hidden !important; }

.bg-light { background-color: @gray-light; }
.bg-lighter { background-color: @gray-lighter; }
.bg-dark { background-color: @gray-dark; }
.bg-dark-lighten { background-color: lighten(@gray-dark, 5%) !important; }
.bg-white { background-color: #fff !important; }
.bg-green { background-color: @green !important; }
.bg-green-dark { background-color: @green-dark !important; }
.bg-rice-cake { background-color: @rice-cake !important; }
.bg-rice-cake-dark { background-color: @rice-cake-dark !important; }

.bg-hover-transparent:hover { background-color: transparent !important; }
.bg-focus-transparent:focus { background-color: transparent !important; }
.bg-hover-rice-cake:hover { background-color: @rice-cake !important; }
.bg-focus-rice-cake:focus { background-color: @rice-cake !important; }
.border-bottom-hover-rice-cake:hover { border-bottom-color: @rice-cake !important; }
.border-bottom-focus-rice-cake:focus { border-bottom-color: @rice-cake !important; }



.text-white { color: white !important; }
.text-light { color: @gray-light !important; }
.text-gray { color: @gray !important; }
.text-dark { color: @gray-dark !important; }
.text-rice-cake { color: @rice-cake !important; }
.text-green { color: @green !important; }

.hvr-text-white:hover { color: white !important; }
.hvr-text-light:hover { color: @gray-light !important; }

.font-weight-light { font-weight: 300 !important; }
.font-weight-normal { font-weight: 400 !important; }
.font-weight-bold { font-weight: 600 !important; }
.font-weight-bolder { font-weight: 700 !important; }


// border
.border { border: 1px solid @gray-light; }
.border-bottom { border-bottom: 1px solid @gray-light; }
.border-top { border-top: 1px solid @gray-light; }
.border-left { border-left: 1px solid @gray-light; }
.border-left { border-left: 1px solid @gray-light; }
.border-right { border-right: 1px solid @gray-light; }


.border-primary {border-color: @brand-primary !important; }
.border-green {border-color: @green !important; }
.border-light {border-color: @gray-light !important; }
.border-lighter {border-color: @gray-lighter !important; }
.border-rice-cake {border-color: @rice-cake !important; }
.border-rice-cake-dark {border-color: @rice-cake-dark !important; }
.border-2 { border-width: 2px !important; }
.border-0 { border-width: 0 !important; }
.border-bottom-0 { border-bottom-width: 0 !important; }



// shadow
.shadow { box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.15); }
.shadow-sm { box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.075); }
.shadow-none { box-shadow: none; }

.hvr-shadow-reverse:not(:hover) { box-shadow: none; }
.hvr-shadow:hover { .shadow; }
.hvr-shadow-sm:hover { .shadow-sm; }

// Line height
.leading-none { line-height: 1 !important; }
.leading-tight { line-height: 1.25 !important; }
.leading-base { line-height: 1.5 !important; }
.leading-relaxed { line-height: 1.65 !important; }
.leading-loose { line-height: 2 !important; }

// sizes
.h-auto { height: auto !important; }
.h-100 { height: 100% !important; }
.max-h-100 { max-height: 100% !important; }
.min-h-100 { min-height: 100% !important; }
.min-vh-100 { min-height: 100vh !important; }

.w-50 { width: 50% !important; }
.w-100 { width: 100% !important; }
.w-inherit { width: inherit !important; }
.w-divider { width: 2em !important; }


@spacer-xs: 5px;
@spacer-sm: 10px;
@spacer-md: 12px;
@spacer-1: 20px;
@spacer-2: 40px;
@spacer-3: 60px;

.mb-lastchild-0 > *:last-child { margin-bottom: 0 !important; }
.mt-auto { margin-top: auto; }
.mt-0 { margin-top: 0; }
.mb-0 { margin-bottom: 0;}


.mt-xs { margin-top: @spacer-xs; }
.mb-xs { margin-bottom: @spacer-xs; }
.mt-sm { margin-top: @spacer-sm; }
.mb-sm { margin-bottom: @spacer-sm  !important; }
.mr-0 { margin-right: 0 !important; }
.mr-xs { margin-right: @spacer-xs; }
.mr-sm { margin-right: @spacer-sm; }
.mr-1 { margin-right: @spacer-1; }
.mr-auto { margin-right: auto; }
.ml-xs { margin-left: @spacer-xs; }
.ml-sm { margin-left: @spacer-sm; }
.ml-1 { margin-left: @spacer-1; }
.ml-auto { margin-left: auto; }

.mt-1 { margin-top: @spacer-1; }
.mb-1 { margin-bottom: @spacer-1; }

.mt-2 { margin-top: @spacer-2; }
.mb-2 { margin-bottom: @spacer-2; }

.mt-3 { margin-top: @spacer-3; }
.mb-3 { margin-bottom: @spacer-3; }

.mx-auto { margin-right: auto; margin-left: auto; }
.mx-nxs { margin-right: -@spacer-xs !important; margin-left: -@spacer-xs !important; }
.mx-nsm { margin-right: -@spacer-sm !important; margin-left: -@spacer-sm !important; }
.mx-n1 { margin-right: -@spacer-1 !important; margin-left: -@spacer-1 !important; }
.mx-0 { margin-right: 0; margin-left: 0; }

.my-auto { margin-top: auto; margin-bottom: auto; }
.my-0 { margin-top: 0; margin-bottom: 0; }

@media (min-width: @screen-md) {
  .mb-md-0 { margin-bottom: 0 !important; }
}


.p-xs { padding: @spacer-xs; }
.p-sm { padding: @spacer-sm; }
.py-0 { padding-top: 0; padding-bottom: 0; }
.py-0i { padding-top: 0 !important; padding-bottom: 0 !important; }
.py-xs { padding-top: @spacer-xs; padding-bottom: @spacer-xs; }
.py-sm { padding-top: @spacer-sm; padding-bottom: @spacer-sm; }
.py-1 { padding-top: @spacer-1; padding-bottom: @spacer-1; }
.p-1 { padding: @spacer-1; }
.pt-xs { padding-top: @spacer-xs; }
.pt-sm { padding-top: @spacer-sm; }
.pt-1 { padding-top: @spacer-1; }
.pb-1 { padding-bottom: @spacer-1; }
.py-2 { padding-top: @spacer-2; padding-bottom: @spacer-2; }
.pt-2 { padding-top: @spacer-2; }

.pl-0 { padding-left: 0; }
.pr-0 { padding-right: 0; }
.px-0 { padding-left: 0; padding-right: 0; }
.px-xs { padding-left: @spacer-xs; padding-right: @spacer-xs; }
.px-sm { padding-left: @spacer-sm; padding-right: @spacer-sm; }
.px-1 { padding-left: @spacer-1; padding-right: @spacer-1; }
.px-2 { padding-left: @spacer-2; padding-right: @spacer-2; }
.pr-sm { padding-right: @spacer-sm; }



// floats
.float-none { float: none !important; }
.clear-both { clear: both; }
.clear-left { clear: left; }
.clear-right { clear: right; }

.cursor-pointer,
[ng-click] { cursor: pointer; }

.navbar-brand-image {

  padding-right: 0;
  padding-left: 0;
  
  float: none;
  display: inline-block;
  height: auto;
  img {
    height: 85px;
    @media screen and (min-width: @screen-xs) {
      height: 95px;
    }
  }
}
.w-sidebar { width: 320px !important; }
.col-sidebar {
  flex: 0 0 320px;
  max-width: 320px;

  @media (max-width: @grid-float-breakpoint-max) {
    left: -320px;
    position: fixed;
    transition: left .25s ease-out;
    z-index: 1;
    top: 0;
    bottom: 0;
    &.open {
      left: 0px;
    }
  }
}

.left-sidebar { left: 320px !important; }
.left-0 { left: 0 !important; }
.bottom-0 { bottom: 0 !important; }
.right-0 { right: 0 !important; }
.top-0 { top: 0 !important; }

// Animations
.transition-left { transition: left .25s ease-out; }
.transition-delay-2s { transition-delay: 0.2s; }
.transition-delay-3s { transition-delay: 0.3s; }


.uib-datepicker-popup.dropdown-menu {
  padding-top: 0;
}


.page-title {
  margin-top: @font-size-h3;
  margin-bottom: @font-size-h2;
  .small,
  small {
    color: lighten(@green, 10%);
    display: block;
    line-height: @line-height-large;
  }
  .meta {
    font-size: @font-size-base;
  }
}

.section-heading {
  margin-top:0;
  text-transform: uppercase;
  color: @green-dark;
}


// radius

.rounded-circle {
  border-radius: 50% !important;
}

.media {

  .media-right,
  .media > .pull-right {
    padding-left: @panel-body-padding;
    padding-bottom: @panel-body-padding;
  }

  .media-left,
  .media > .pull-left {
    padding-right: @panel-body-padding;
    padding-bottom: @panel-body-padding;
  }

  .media-date-byline {
    border-top: 1px solid @gray-light;
    border-bottom: 1px solid @gray-light;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-bottom: @padding-base-vertical;
  }

  .media-summary {
    p:last-child {
      margin-bottom: @padding-base-vertical;
    }
  }

  &.media-responsive {
    .media-right, .media-left {

      @media (max-width: @screen-xs) {
        display: block;
        float: none !important;
        padding-right: 0;
        padding-left: 0;

        .media-object {
          width: 100%;
        }
      }

      @media (min-width: @screen-xs + 1) {
        .media-object {
          width: 200px;
        }
      }

      @media (min-width: @screen-md) {
        .media-object {
          width: 160px;
        }
      }

    }
  }
}

.page-header {
  margin-top: 0;
  color: @green-dark;
  h1, h2, h3 {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.page-header-img {
  margin-bottom:  (@line-height-computed/2);
}

// breadcrumb
.breadcrumb {
  a { color: @green-dark; }
  > li + li:before {
    .fa-icon;
    padding-right: 0;
  }
}


// Nav dropdown

.nav > li.dropdown {
  > a[data-toggle="dropdown"] {
    padding-right: 30px;
    &:after {
      .fa-icon();
      content: @fa-var-chevron-down;
      position: absolute;
      top: ~"calc(50% - 6px)";
      right: 9px;
      font-size: 0.75em;
    }
  }
  &.dropdown-reverse > a[data-toggle="dropdown"]:after {
    content: @fa-var-chevron-up;
  }
}

[uib-dropdown] {
  display: inline-block;
  [uib-dropdown-toggle] {
    padding-right: 30px;
    margin-bottom: 0;

      &:after {
      .fa-icon();
      content: @fa-var-chevron-down;
      position: absolute;
      top: ~"calc(50% - 6px)";
      right: 9px;
      font-size: 0.75em;
    }
  }
}


.login-wrapper {
  width: 300px;
  margin: auto;
  margin-bottom: @grid-gutter-width;
}

.modal-content .login-wrapper {
  margin-top: @grid-gutter-width;
}


// Form extends
.form-control[disabled]:not(.disabled), .form-control[readonly], fieldset[disabled] .form-control {
  border-width: 0;
  box-shadow: none;
  padding: 7px 0;
}

.form-control.ui-select-search {
  width: 100% !important;
}

.ui-select-refreshing {
  position: absolute;
  right: 6px;
  top: ~'calc(50% - 8px)';
}

.ui-select-bootstrap .ui-select-toggle>a.btn {
  height: auto;
  margin-top: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  border-width: 0;
  &, &:hover, &:active, &:focus {
    text-decoration: none;
  }
}


.ui-select-choices-row-inner {
  border-bottom: 1px solid @rice-cake-dark;

  >span, >div {
    white-space: normal;
  }
}

.form-section {
    border-bottom: 1px solid @gray-light;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.form-section-heading {
  text-transform: uppercase;
  &:after {
    display: block;
    content: '';
    width: 2em;
    height: 2px;
    background-color: @green-dark;
    margin-top: 8px;
  }
}
.errors-list,
.parsley-errors-list {
  color: @brand-danger;
  list-style: none;
  margin: 5px 0 0;
  padding: 0;
  font-size: @font-size-small;
  li {
    display: inline-block;
  }
}

.has-error .input-group .input-group-btn > .btn {
  border-color: @brand-danger;
}
.btn-default-focus,
.btn-default.form-control {
  &, &:focus, &:active {
    background-color: transparent;
  }
}
.field.form-group {
  position: relative;
}
.progress.progress-upload {
  margin: 0 !important;
  height: 5px !important;
}

.form-group .instructions {
  color: lighten(@text-color, 25%);
  font-size: @font-size-small;
  margin-bottom: 5px;

  > *:last-child {
    margin-bottom: 0;
  }

  ol, ul {
    padding-left: 1.2em;
  }

}

.form-row {
  .clearfix;
  margin-left: (-1 * (@grid-gutter-width / 4));
  margin-right: (-1 * (@grid-gutter-width / 4));

  > [class*=col-] {
    padding-left: (@grid-gutter-width / 4);
    padding-right: (@grid-gutter-width / 4);
  }
}

.control-label, label {
  + .instructions {
      margin-top: -5px;
  }
  .instructions {
    font-weight: 400;
  }
}

.control-text {
  padding: 7px 0;
  color: #555;
}

.list-upper-alpha {
  list-style: upper-alpha;
}


.hide-labels .flex [data-title]:before {
  display: none;
}
@media screen and (min-width: @screen-sm-min) {
  .hide-labels-sm .flex [data-title]:before {
    display: none;
  }
}
  
.no-choices {
  padding: @padding-base-vertical @padding-base-horizontal;
  display: block;
  color: @brand-warning;
}

.d-block { display: block !important; }
.d-inline-block { display: inline-block !important; }
.d-flex { display: flex !important; }
.d-sm-flex {
  @media (min-width: @screen-sm) {
    display: flex !important;
  }
}
.d-md-flex {
  @media (min-width: @screen-md) {
    display: flex !important;
  }
}

.order-1 { order: 1 !important; }
@media (min-width: @screen-md) {
  .order-md-1 { order: 1 !important; }
}
.flex-wrap { flex-wrap: wrap !important; }
.flex-fill { flex: 1 1 auto !important; }
.flex-grow-1 { flex-grow: 1 !important; }
.flex-shrink-0 { flex-shrink: 0 !important; }
.flex-shrink-1 { flex-shrink: 1 !important; }
.justify-content-center { justify-content: center !important; }
.justify-content-between { justify-content: space-between !important; }
.align-items-center { align-items: center !important; }
.align-items-end { align-items: flex-end !important; }
.align-self-start { align-self: flex-start !important; }
.align-self-center { align-self: center !important; }

.flex-column { flex-direction: column !important; }

.flex-md-column { 
  @media (min-width: @screen-md) {
    flex-direction: column !important;
  }
 }

.flex-md-row { 
  @media (min-width: @screen-md) {
    flex-direction: row !important;
  }
 }


.flex {
  display: flex;
  justify-content: space-between;
  margin-left: -10px;
  @media screen and (max-width: @screen-sm) {
    display: block;
  }


  [data-title] {
    padding: 0 10px;
    &:before {
      content: attr(data-title) ": ";
      display: block;
      color: @gray;
      font-size: @font-size-small;
      font-weight: bold;
      &:not(:empty) {
        margin-bottom: 2px;
      }
      @media screen and (max-width: @screen-sm) {
        display: inline;
      }
    }
    &.data-title-inline:before {
      display: inline;
    }
  }

  [data-title="Credits claimed"]:before {
    @media screen and (max-width: @screen-sm) {
      display: inline;
    }
  }

  .column {
    flex: 1;
    font-size: @font-size-small + 2;

    &.is-2 {
      flex:2;
    }

    &-sm-4 {
      @media screen and (min-width: @screen-sm) {
        flex: 0 0 percentage(4/12);
        max-width: percentage(4/12);
      }
    }
  }
}



.panel-footer .list-group-item-totals {
  padding-right: 10px;
  padding-top: 5px;
  @media screen and (min-width: @screen-sm-min) {
    padding-right: 60px;
  }
}

.panel-footer-warning {
  background-color: @state-warning-bg; 
  color: @state-warning-text;
  .list-group-item-totals {
    padding-top: 0;
  }
}

.list-group-item-totals {
    text-align: right;
    padding-right: 25px;

    @media screen and (min-width: @screen-sm-min) {
      padding-right: 75px;
    }
}
.list-group-item-buttons {
  list-style: none;
  margin: 0 -2px 0 0;
  padding: 0;
  min-width: 40px;

  > li {
    display: inline-block;
    padding: 0 2px;
    a {
      display: block;
    }
  }
}

label.required:after {
  content: ' *';
  color: @brand-danger;
}


.password-wrapper {
  position: relative;
}
.password-toggle {
  position: absolute;
  z-index: 1;
  right: 12px;
  top: ~"calc(50% - 6px)";
  line-height: 1;
  font-size: @font-size-small;
  cursor: pointer;
  b, em {
    line-height:1;
    font-weight: 400;
    font-style: normal;
    display: none;
  }
}

input[type="password"] {
  + .password-toggle b {
    display: inline;
  }
}

input[type="text"] {
  + .password-toggle em {
    display: inline;
  }
}

.checkbox-remember {
  font-size: @font-size-small;
}

.form-group-buttons {
  .clearfix();
  [uib-dropdown] ,
  .btn:not([uib-dropdown-toggle]) {
    margin-bottom: @padding-large-vertical;
  }
}

.form-group-buttons-bottom {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid @rice-cake-dark;
}

.body-bg-light {
  background-color: lighten(@body-bg, 10%)
}

// outlines
.outline {
  padding: 10px;
  margin-bottom:@line-height-computed;
  > *:last-child {
    margin-bottom:0;
  }
}
.outline-success {
  outline: 1px solid @brand-success;
}

.outline-danger {
  outline: 1px solid @brand-danger;
}

.outline-warning {
  outline: 1px solid @brand-warning;
}

.outline-default {
  outline: 1px solid @gray-light;
}


// Splash
.page-splash {
    z-index: 99999 !important;
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #333;
    opacity: .9;
    pointer-events: auto;
    backface-visibility: hidden;
    transition: opacity 0.3s linear;
}

.page-splash-message {
    text-align: center;
    margin: 20% auto 0 auto;
    font-size: 400%;
    font-family: "Segoe UI", Arial, Helvetica, sans-serif;
    font-weight: normal;
    text-shadow: 2px 2px #000000;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.15);
    text-transform: uppercase;
    text-decoration: none;
    color: #F58A00;
    padding: 0;
}

.page-splash-message.page-splash-message-subtle {
  margin: 30% auto 0 auto;
  font-size: 200%;
}


.progress,
.page-progress-bar {
    margin: 30px 10% !important;
}

.ngplus-overlay-background {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: fixed;
    z-index: 10000;
    background-color: #808080;
    opacity: 0.2;
}

.ngplus-overlay-content {
    position: fixed;
    font-weight: bold;
    width: 85px;
    height: 70px;
    z-index: 10000;
    text-align: center;
}

/*Optional Animations*/
.dissolve-animation.ng-hide-remove,
.dissolve-animation.ng-hide-add {
  display: inline !important;
  transition: 0.5s linear all;
}

.dissolve-animation.ng-hide-remove.ng-hide-remove-active,
.dissolve-animation.ng-hide-add {
  opacity: 1;
}

.dissolve-animation.ng-hide-add.ng-hide-add-active,
.dissolve-animation.ng-hide-remove {
    opacity: 0;
}

.page-spinner-message {
    text-align: center;
    font-size: 400%;
    font-family: "Segoe UI", Arial, Helvetica, sans-serif;
    font-weight: normal;
    text-shadow: 2px 2px #000000;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.15);
    text-transform: uppercase;
    text-decoration: none;
    color: #F58A00;
    padding: 0;
}

.page-spinner-message.page-spinner-message-subtle {
  margin: 30% auto 0 auto;
  font-size: 200%;
}

.overlay-message {
  font-size: 200%;
}
.spinner {
  margin: 20% auto 0 auto;
  left: auto;
  top: auto !important;
}

// Lead extended
.lead-sm {
  font-size: floor((@font-size-base));
  @media (min-width: @screen-sm-min) {
    font-size: (@font-size-base * 1.15);
  }
}

.fz-sm-lg {

  @media (min-width: @screen-sm) {
    font-size: (@font-size-base * 1.15);
  }
}

.btn-group-spaced .btn+.btn {
  margin-left: 5px;
}

.row-image {
  > div {
    padding: 0;
  }
  margin: 0 -1px;

}

.image-item {
  position: relative;
  padding-bottom: 75%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}



/*#endregion*/

/*#region Angular ng-show dissolve animation */
.dissolve-animation.ng-hide-remove,
.dissolve-animation.ng-hide-add {
    position: fixed !important;
    display: inline !important;
    transition: 0.5s linear all;
}

.dissolve-animation.ng-hide-remove.ng-hide-remove-active,
.dissolve-animation.ng-hide-add {
opacity: 1;
}

.dissolve-animation.ng-hide-add.ng-hide-add-active,
.dissolve-animation.ng-hide-remove {
    opacity: 0;
}
/*#endregion */


@media print {
  .show-print.ng-hide { display: initial !important; }
  html,body {
    overflow-x: visible;
  }
  
  #global-sidebar {
    left: -260px;
  }
  #global-sidebar,
  .list-group-item-buttons,
  .panel-footer .btn,
  .navbar-default {
    display: none;
  }

  .panel-footer .list-group-item-totals {
    display: block;
    float: none !important;
    text-align: right;
  }
}

.btn-outline-primary {
  .button-variant(@btn-primary-bg, transparent, @btn-primary-border);
}
.btn-outline-default {
  .button-variant(@btn-default-color, transparent, @btn-default-border);
}
.btn-outline-danger {
  .button-variant(@btn-danger-bg, transparent, @btn-danger-border);
}


.text-decoration-none {
  &,&:hover, &:visited { text-decoration: none; }
}

//positions
.sticky-top {
  position: sticky;
  top: 0;
}

.position-initial { position: initial !important; }
.position-relative { position: relative !important; }
.position-absolute { position: absolute !important; }
.position-fixed { position: fixed !important; }

@media (min-width: @screen-sm) {
  .position-sm-absolute { position: absolute !important; }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

// opacity
.alpha-25 { opacity: 0.25; }
.alpha-50 { opacity: 0.50; }
.alpha-75 { opacity: 0.75; }

.zindex-n1 {
  z-index: -1;
}
.zindex-1 {
   z-index: 1;
}
.zindex-fixed {
   z-index: 1060;
}

// links
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0,0,0,0);
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}